<template>
  <div class="container">
    <v-container fluid class="bg-white mt-65 p-10">
      <v-autocomplete
        dense
        outlined
        hide-no-data
        label="Select Vin"
        autocomplete="none"
        v-model="vinSearchId"
        :search-input.sync="searchVinId"
        :items="vinSearch"
        item-text="vin"
        item-value="id"
        :loading="containersLoading"
        style="width: 50%; display: inline-block"
      ></v-autocomplete>
      <v-btn
        depressed
        color="primary"
        class="ml-8"
        @click="vinSearchTableData()"
      >
        Add
      </v-btn>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Year</th>
              <th class="text-left">Make</th>
              <th class="text-left">Model</th>
              <th class="text-left">Color</th>
              <th class="text-left">Vin</th>
              <th class="text-left">Status</th>
              <th class="text-left">Title Number</th>
              <th class="text-left">Location</th>
              <th class="text-left">Lot Number</th>
              <th class="text-left">CUSTOMER NAME</th>
              <th class="text-left">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="vinTable in vehicles" :key="vinTable.id">
              <td>{{ vinTable.year }}</td>
              <td>{{ vinTable.make }}</td>
              <td>{{ vinTable.model }}</td>
              <td>{{ vinTable.color }}</td>
              <td>{{ vinTable.vin }}</td>
              <td>{{ vinTable.status_name }}</td>
              <td>{{ vinTable.title_number }}</td>
              <td>{{ vinTable.location }}</td>
              <td>{{ vinTable.lot_number }}</td>
              <td>{{ vinTable.customer_name }}</td>

              <td>
                <v-btn text @click="checkOutDelete(vinTable.id)" color="red">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <br />

      <br />

      <div class="export-modal__title">customar Info</div>
      <v-row>
        <v-col cols="12" md="6">
          <!-- <v-autocomplete
            dense
            outlined
            v-model="form.customer_user_id"
            :items="items"
            item-text="customer_name"
            item-value="user_id"
            :search-input.sync="customarUserId"
            label="CUSTOMER"
            autocomplete="none"
            placeholder="Customer"
            :loading="customarIdLoading"
            :error-messages="errors.customer_user_id"
          ></v-autocomplete> -->
          <v-autocomplete
            dense
            outlined
            v-model="form.customer_user_id"
            :items="items"
            label="CUSTOMER"
            item-text="customer_name"
            item-value="user_id"
            :search-input.sync="customarUserId"
            no-filter
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="
                      data.item.customer_name +
                      ' (' +
                      data.item.legacy_customer_id +
                      ')'
                    "
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.company_name"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <!-- <v-text-field v-model="form.customer_name" label="CUSTOMER" outlined dense :error-messages="errors.customer"></v-text-field> -->
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            disabled
            v-model="customarCompanyName"
            label="CUSTOMER COMPANY"
            outlined
            dense
            :error-messages="errors.customerCompany"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="legacyCustomerId"
            :disabled="true"
            label="CUSTOMER ID"
            :error-messages="errors.legacyCustomerId"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <br />

      <hr />
      <br />

      <div class="export-modal__title">Export Info</div>
      <v-row>
        <v-col cols="12" md="6">
          <v-menu
            ref="datePicker2"
            v-model="datePicker2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="form.loading_date"
                name="loading_date"
                label="Loading Date"
                prepend-icon="mdi-calendar"
                clearable
                v-bind="attrs"
                :error-messages="errors.loading_date"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              style="height: 350px"
              v-model="form.loading_date"
              @input="datePicker2 = false"
              no-title
              scrollable
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="6">
          <v-menu
            ref="datePicker1"
            v-model="datePicker1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="form.export_date"
                name="export_date"
                label="Export Date"
                prepend-icon="mdi-calendar"
                clearable
                :error-messages="errors.export_date"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              style="height: 350px"
              v-model="form.export_date"
              @input="datePicker1 = false"
              no-title
              scrollable
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.broker_name"
            name="broker_name"
            label="BROKER NAME"
            outlined
            dense
            :error-messages="errors.broker_name"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.booking_number"
            name="booking_number"
            label="BOOKING NUMBER"
            outlined
            dense
            :error-messages="errors.booking_number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.oti"
            name="oti"
            label="OTI NUMBER"
            outlined
            dense
            :error-messages="errors.oti"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-menu
            ref="datePicker3"
            v-model="datePicker3"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="form.eta"
                name="eta"
                label="ETA"
                prepend-icon="mdi-calendar"
                clearable
                v-bind="attrs"
                v-on="on"
                :error-messages="errors.eta"
              ></v-text-field>
            </template>
            <v-date-picker
              style="height: 350px"
              v-model="form.eta"
              @input="datePicker3 = false"
              no-title
              scrollable
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.ar_number"
            name="ar_number"
            label="AR NO"
            outlined
            dense
            :error-messages="errors.ar_number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.xtn_number"
            name="xtn_number"
            label="XTN NO"
            outlined
            dense
            :error-messages="errors.xtn_number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.seal_number"
            name="seal_number"
            label="SEAL NO"
            outlined
            dense
            :error-messages="errors.seal_number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.container_number"
            name="container_number"
            label="CONTAINER NO"
            outlined
            dense
            :error-messages="errors.container_number"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-menu
            ref="datePicker4"
            v-model="datePicker4"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="form.cutt_off"
                name="cutt_off"
                label="CUT OFF"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              style="height: 350px"
              v-model="form.cutt_off"
              @input="datePicker4 = false"
              no-title
              scrollable
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.vessel"
            name="vessel"
            label="VESSEL"
            outlined
            dense
            :error-messages="errors.vessel"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.voyage"
            name="voyage"
            label="VOYAGE"
            outlined
            dense
            :error-messages="errors.voyage"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.terminal"
            name="terminal"
            label="TERMINAL"
            outlined
            dense
            :error-messages="errors.terminal"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <!-- <v-text-field
            v-model="form.streamship_line"

            name="streamship_line"
            label="STREAMSHIP LINE"
            outlined
            dense
            :error-messages="errors.streamship_line"
          ></v-text-field>  -->
          <v-select
            v-model="form.streamship_line"
            :items="streamship_lines"
            label="STREAMSHIP LINE"
            dense
            outlined
            :error-messages="errors.streamship_line"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.destination"
            name="destination"
            label="DESTINATION"
            outlined
            dense
            :error-messages="errors.destination"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.itn"
            name="itn"
            label="ITN"
            outlined
            dense
            :error-messages="errors.itn"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-textarea
            v-model="form.contact_details"
            name="contact_details"
            outlined
            label="CONTACT DETAILS"
            rows="1"
          ></v-textarea>
        </v-col>
      </v-row>

      <br />
      <hr />
      <br />

      <div class="export-modal__title">additional Info</div>
      <v-row>
        <v-col cols="12" md="6">
          <v-textarea
            outlined
            v-model="form.special_instruction"
            name="special_instruction"
            label="SPECIAL INSTRUCTION"
            row-height="10"
            rows="1"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            dense
            outlined
            item-text="name"
            item-value="id"
            v-model="form.container_type"
            name="container_type"
            :items="containerData"
            placeholder="CONTAINER TYPE"
            label="CONTAINER TYPE"
            autocomplete="none"
            :loading="containersLoading"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            dense
            outlined
            item-text="name"
            item-value="id"
            v-model="form.port_of_loading"
            name="port_of_loading"
            :items="portLoadingData"
            placeholder="PORT OF LOADING"
            label="PORT OF LOADING"
            autocomplete="none"
            :loading="containersLoading"
            :error-messages="errors.port_of_loading"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            dense
            outlined
            item-text="name"
            item-value="id"
            v-model="form.port_of_discharge"
            name="port_of_discharge"
            :items="portDischargeData"
            placeholder="PORT OF DISCHARGE"
            label="PORT OF DISCHARGE"
            autocomplete="none"
            :loading="containersLoading"
            :error-messages="errors.port_of_discharge"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.bol_note"
            name="bol_note"
            label="BOL NOTE"
            outlined
            dense
            :error-messages="errors.bol_note"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            outlined
            v-model="form.bol_remarks"
            name="bol_remarks"
            label="BOL REMARKS"
            row-height="10"
            rows="1"
          ></v-textarea>
        </v-col>
      </v-row>
      <br />
      <hr />
      <br />

      <div class="export-modal__title">dock recipt-more Info</div>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.awb_number"
            name="awb_number"
            label="B/L OR AWB NUMBER"
            outlined
            dense
            :error-messages="errors.awb_number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.export_reference"
            name="export_reference"
            label="EXPORT REFERANCE"
            outlined
            dense
            :error-messages="errors.export_reference"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            outlined
            v-model="form.forwarding_agent"
            name="forwarding_agent"
            label="FORWARDING AGENT"
            row-height="10"
            rows="2"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            outlined
            v-model="form.domestic_routing_instruction"
            name="domestic_routing_instruction"
            label="DOMESTIC ROUTING INSTRUCTION"
            row-height="10"
            rows="2"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.pre_carriage"
            name="pre_carriage"
            label="PRE CARRIAGE BY"
            outlined
            dense
            :error-messages="errors.pre_carriage"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.place_of_receipt"
            name="place_of_receipt"
            label="PLACE OF RECEIPT BY PRE CARRIAGE"
            outlined
            dense
            :error-messages="errors.place_of_receipt"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.final_destination"
            name="final_destination"
            label="FINAL DESTINATION"
            outlined
            dense
            :error-messages="errors.final_destination"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.loading_terminal"
            name="loading_terminal"
            label="LOADING TERMINAL"
            outlined
            dense
            :error-messages="errors.loading_terminal"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.dock_container_type"
            name="dock_container_type"
            label="DOCK CONTAINER TYPE"
            outlined
            dense
            :error-messages="errors.dock_container_type"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.number_of_packages"
            name="number_of_packages"
            label="NUMBER OF PACKAGES"
            outlined
            dense
            :error-messages="errors.number_of_packages"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.by"
            name="by"
            label="BY"
            outlined
            dense
            :error-messages="errors.by"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.exporting_carrier"
            name="exporting_carrier"
            label="EXPORTING CARRIER"
            outlined
            dense
            :error-messages="errors.exporting_carrier"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-menu
            ref="datePicker5"
            v-model="datePicker5"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="form.date"
                name="date"
                label="DATE"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              style="height: 350px"
              v-model="form.date"
              @input="datePicker5 = false"
              no-title
              scrollable
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="6">
          <v-menu
            ref="datePicker6"
            v-model="datePicker6"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="form.auto_receive_date"
                label="AUTO RECEIVEING DATE"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              style="height: 350px"
              v-model="form.auto_receive_date"
              @input="datePicker6 = false"
              no-title
              scrollable
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="6">
          <v-menu
            ref="datePicker7"
            v-model="datePicker7"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="form.auto_cut_off"
                label="AUTO CUT OFF"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              style="height: 350px"
              v-model="form.auto_cut_off"
              @input="datePicker7 = false"
              no-title
              scrollable
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="6">
          <v-menu
            ref="datePicker8"
            v-model="datePicker8"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="form.vessel_cut_off"
                label="VESSEL CUT OFF"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              style="height: 350px"
              v-model="form.vessel_cut_off"
              @input="datePicker8 = false"
              no-title
              scrollable
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="6">
          <v-menu
            ref="datePicker9"
            v-model="datePicker9"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="form.sale_date"
                label="SALE DATE"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              style="height: 350px"
              v-model="form.sale_date"
              @input="datePicker9 = false"
              no-title
              scrollable
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <br />
      <hr />
      <br />

      <div class="export-modal__title">houston customs cover latter</div>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.vehicle_location"
            name="vehicle_location"
            label="VEHICLE LOCATION"
            outlined
            dense
            :error-messages="errors.vehicle_location"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.exporter_id"
            name="exporter_id"
            label="EXPORTAR ID"
            outlined
            dense
            :error-messages="errors.exporter_id"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.exporter_type_issuer"
            name="exporter_type_issuer"
            label="EXPORTER TYPE ISSUER"
            outlined
            dense
            :error-messages="errors.exporter_type_issuer"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.transportation_value"
            name="transportation_value"
            label="TRANSPORTATION VALUE"
            outlined
            dense
            :error-messages="errors.transportation_value"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.exporter_dob"
            name="exporter_dob"
            label="EXPORTER DOB"
            outlined
            dense
            :error-messages="errors.exporter_dob"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.ultimate_consignee_dob"
            name="ultimate_consignee_dob"
            label="ULTIMATE CONSIGNEE DOB"
            outlined
            dense
            :error-messages="errors.ultimate_consignee_dob"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-autocomplete
            dense
            outlined
            v-model="form.consignee"
            :items="consigneeData"
            item-text="consignee_name"
            item-value="consignee_name"
            placeholder="CONSIGNEE"
            label="CONSIGNEE"
            autocomplete="none"
            :loading="containersLoading"
            :error-messages="errors.consignee"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="6">
          <v-autocomplete
            dense
            outlined
            v-model="form.notify_party"
            :items="consigneeData"
            item-text="consignee_name"
            item-value="consignee_name"
            placeholder="NOTIFY PARTY"
            label="NOTIFY PARTY"
            autocomplete="none"
            :loading="containersLoading"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="6">
          <v-autocomplete
            dense
            outlined
            v-model="form.menifest_consignee"
            :items="consigneeData"
            item-text="consignee_name"
            item-value="consignee_name"
            placeholder="MANIFEST CONSIGNEE"
            label="MANIFEST CONSIGNEE"
            autocomplete="none"
            :loading="containersLoading"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <hr />
      <br />

      <div class="export-modal__title">EXPORT INVOICE</div>
      <v-row>
        <v-col cols="12" md="12">
          <vue-dropzone
            ref="vueDropzoneExprotInvoice"
            id="dropzone"
            @vdropzone-removed-file="removeFileExportInvoice"
            @vdropzone-sending="sendingEventExprotInvoice"
            @vdropzone-success="storeSuccessExprotInvoice"
            @vdropzone-error="storeErrorExprotInvoice"
            :options="dropzoneOptionsExprotInvoice"
          ></vue-dropzone>
          <button @click="removeAllFilesExprotInvoice">Remove All Files</button>
        </v-col>
      </v-row>
      <br />

      <div class="export-modal__title">CONTAINER IMAGES</div>
      <v-row>
        <v-col cols="12" md="12">
          <vue-dropzone
            ref="vueDropzoneContinerImage"
            id="dropzone"
            @vdropzone-removed-file="removeFileContinerImage"
            @vdropzone-sending="sendingEventContinerImage"
            @vdropzone-success="storeSuccessContinerImage"
            @vdropzone-error="storeErrorContinerImage"
            :options="dropzoneOptionsContinerImage"
          ></vue-dropzone>
          <button @click="removeAllFilesContinerImage">Remove All Files</button>
        </v-col>
      </v-row>
      <br />

      <div class="export-modal__title">EMPTY CONTAINER IMAGES</div>
      <v-row>
        <v-col cols="12" md="12">
          <vue-dropzone
            ref="vueDropzoneEmptyContainer"
            id="dropzone"
            @vdropzone-removed-file="removeFileEmptyContainer"
            @vdropzone-sending="sendingEventEmptyContainer"
            @vdropzone-success="storeSuccessEmptyContainer"
            @vdropzone-error="storeErrorEmptyContainer"
            :options="dropzoneOptionsEmptyContainer"
          ></vue-dropzone>
          <button @click="removeAllFilesEmptyContainer">
            Remove All Files
          </button>
        </v-col>
      </v-row>

      <br />

      <div class="export-modal__title">Loading Photos</div>
      <v-row>
        <v-col cols="12" md="12">
          <vue-dropzone
            ref="refLoadingPhotos"
            id="idLoadingPhotos"
            @vdropzone-removed-file="removeFileLoadingPhotos"
            @vdropzone-sending="sendingEventLoadingPhotos"
            @vdropzone-success="storeSuccessLoadingPhotos"
            @vdropzone-error="storeErrorLoadingPhotos"
            :options="dropzoneOptionsLoadingPhotos"
          ></vue-dropzone>
          <button @click="removeAllFilesLoadingPhotos">Remove All Files</button>
        </v-col>
      </v-row>

      <br />

      <div class="export-modal__title">Loaded Photos</div>
      <v-row>
        <v-col cols="12" md="12">
          <vue-dropzone
            ref="refLoadedPhotos"
            id="idLoadedPhotos"
            @vdropzone-removed-file="removeFileLoadedPhotos"
            @vdropzone-sending="sendingEventLoadedPhotos"
            @vdropzone-success="storeSuccessLoadedPhotos"
            @vdropzone-error="storeErrorLoadedPhotos"
            :options="dropzoneOptionsLoadedPhotos"
          ></vue-dropzone>
          <button @click="removeAllFilesLoadedPhotos">Remove All Files</button>
        </v-col>
      </v-row>
      <v-btn color="primary" class="mt-7 mr-auto" @click="store()">save</v-btn>
    </v-container>
  </div>
</template>

<script>
import Api from "@/services/Api";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapGetters } from "vuex";
const _token = "Bearer " + localStorage.getItem("authtoken");
export default {
  props: ["id", "show"],
  name: "Manage",
  components: {
    vueDropzone: vue2Dropzone,
  },

  data() {
    return {
      items: [],
      vinSearch: [],
      containersLoading: false,
      customarIdLoading: false,
      dataId: this.id,
      editing: false,
      errors: {},
      searchVinId: "",
      customarUserId: "",
      dialog: this.show,
      loading: false,
      //  modal: false,
      form: {},
      cusotmarAutoComplete: "",
      vinSearchId: "",

      vehicles: [], //vehicle table data
      containerData: [
        { id: 1, name: "1 X 20'HC DRY VAN" },
        { id: 2, name: "1 X 45'HC DRY VAN" },
        { id: 3, name: "1 X 40'HC DRY VAN" },
      ],
      portLoadingData: [
        { id: 1, name: "NEW JERSEY, NWJ" },
        { id: 2, name: "HOUSTON, TX" },
        { id: 3, name: "LOS ANGELES, CA" },
        { id: 4, name: "NEWARK, NJ" },
        { id: 5, name: "SAVANNAH, GA" },
        { id: 6, name: "HOUSTON, TEXAS" },
        { id: 7, name: "HONOLULU, HI" },
        { id: 8, name: "BALTIMORE, MD" },
      ],
      portDischargeData: [
        { id: 1, name: "JEBEL ALI, UAE" },
        { id: 2, name: "AQABA, JORDAN" },
        { id: 3, name: "KARACHI, PAKISTAN" },
        { id: 4, name: "SOHAR, OMAN" },
        { id: 5, name: "UMM QASR, IRAQ" },
        { id: 6, name: "MERSIN, TURKEY" },
        { id: 7, name: "CAMBODIA" },
        { id: 8, name: "BAHRAIN" },
        { id: 9, name: "MUSCAT,OMAN" },
        { id: 10, name: "FREETOWN, SIERRA LEONE" },
        { id: 11, name: "TEMA, GHANA" },
        { id: 12, name: "VIETMAN" },
        { id: 13, name: "BUSAN, KOREA" },
        { id: 14, name: "GERMANY" },
        { id: 15, name: "MISURATA, LIBYA" },
        { id: 16, name: "NIGERIA" },
        { id: 17, name: "DENMARK" },
        { id: 18, name: "BENGHAZI, LIBYA" },
        { id: 19, name: "MYANMAR" },
        { id: 20, name: "SALALAH, OMAN" },
        { id: 21, name: "LIBYA - ALKHOMS" },
      ],
      streamship_lines: [
        "UN",
        "MAERSK",
        "MSC",
        "SAFMARINE",
        "OOCL",
        "ONE",
        "EVERGREEN",
        "YANG MING",
        "HMM",
        "PIL",
        "APL",
        "APM TERMINALS",
        "CMA CGM",
        "COSCO",
        "HAPAG LLOYD",
        "SEALAND",
        "MEDITERRANEAN",
      ],
      consigneeData: [],
      notifyPartyData: [],
      menifestConsigneeData: [],

      costomarInfovalue: "",
      customarCompanyName: "",
      datePicker1: false,
      datePicker2: false,
      datePicker3: false,
      datePicker4: false,
      datePicker5: false,
      datePicker6: false,
      datePicker7: false,
      datePicker8: false,
      datePicker9: false,
      datePicker10: false,
      dropzoneOptionsExprotInvoice: {
        url: `${Api.defaults.baseURL}/exports/1/documents-upload`,
        thumbnailWidth: 150,
        maxFilesize: 10,
        addRemoveLinks: true,
        headers: { "Authorization": _token },
      },
      dropzoneOptionsContinerImage: {
        url: `${Api.defaults.baseURL}/exports/1/photos-upload?type=1`,
        thumbnailWidth: 150,
        addRemoveLinks: true,
        headers: { "Authorization": _token },
      },
      dropzoneOptionsEmptyContainer: {
        url: `${Api.defaults.baseURL}/exports/1/photos-upload?type=2`,
        thumbnailWidth: 150,
        addRemoveLinks: true,
        headers: { "Authorization": _token },
      },

      dropzoneOptionsLoadingPhotos: {
        url: `${Api.defaults.baseURL}/exports/1/photos-upload?type=3`,
        thumbnailWidth: 150,
        addRemoveLinks: true,
        headers: { "Authorization": _token },
      },
      dropzoneOptionsLoadedPhotos: {
        url: `${Api.defaults.baseURL}/exports/1/photos-upload?type=4`,
        thumbnailWidth: 150,
        addRemoveLinks: true,
        headers: { "Authorization": _token },
      },

      removeFilesFromDropzoneOnlyExprotInvoice: false,
      removeFilesFromDropzoneOnlyContinerImage: false,
      removeFilesFromDropzoneOnlyEmptyContainer: false,

      removeFilesFromDropzoneOnlyLoadingPhotos: false,
      removeFilesFromDropzoneOnlyLoadedPhotos: false,
      legacyCustomerId: null,
    };
  },
  computed: {
    ...mapGetters(["cart"]),
  },
  methods: {
    emptyForm() {
      this.form = {
        ar_number: "",
        bol_note: "",
        bol_remarks: "",
        booking_number: "",
        broker_name: "",
        container_number: "",
        container_type: "",
        dock_container_type: "",
        vehicle_ids: [],
        customer_user_id: "",
        cutt_off: "",
        destination: "",
        eta: "",
        export_date: "",

        export_invoice: "",
        consignee: "",
        exporter_dob: "",
        exporter_id: "",
        exporter_type_issuer: "",
        notify_party: "",
        transportation_value: "",
        ultimate_consignee_dob: "",
        vehicle_location: "",
        id: "",
        loading_date: "",
        note: "",
        port_of_discharge: "",
        port_of_loading: "",
        seal_number: "",
        special_instruction: "",
        streamship_line: "",
        terminal: "",
        vessel: "",
        xtn_number: "",

        export_images: [],

        export_invoice_photo: [],
        container_images: [],
        empty_container_images: [],
        loading_photos: [],
        loaded_photos: [],

        voyage: "",
        itn: "",
        contact_details: "",
        awb_number: "",
        export_reference: "",
        forwarding_agent: "",
        domestic_routing_instruction: "",
        pre_carriage: "",
        place_of_receipt: "",
        final_destination: "",
        loading_terminal: "",
        number_of_packages: "",
        by: "",
        exporting_carrier: "",
        date: "",
        auto_receive_date: "",
        auto_cut_off: "",
        vessel_cut_off: "",
        sale_date: "",

        removableFilesExportInvoice: [],
        removableFilesContinerImage: [],
        removableFilesEmptyContainer: [],

        removableFilesLoadingPhotos: [],
        removableFilesLoadedPhotos: [],
      };
    },

    store() {
      this.loading = true;
      this.errors = {};

      // console.log(this.form);

      Api.post(`/exports`, this.form)
        .then((res) => {
          // console.log(res);
          this.dialog = false;
          this.loading = false;
          this.emptyForm();

          this.$emit("dataUpdated");
          this.$toastr.s(res.data.message);
        })
        .catch((err) => {
          // console.log(err.response.data);
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
            this.$toastr.e("Failed to create data! One or More Field Required");
          } else {
            this.dialog = false;
            this.loading = false;
          }
        });
    },
    async update() {
      this.loading = true;
      this.errors = {};
      await Api.put(`/exports/${this.id}`, this.form)
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.emptyForm();
          this.$toastr.s(res.data.message);
          this.$emit("dataUpdated");
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          this.$toastr.e("Failed to update data! " + err);
        });

      this.editing = false;
    },
    editData(db) {
      this.form.ar_number = db.ar_number;
      this.form.bol_note = db.bol_note;
      this.form.bol_remarks = db.bol_remarks;
      this.form.booking_number = db.booking_number;
      this.form.broker_name = db.broker_name;
      this.form.container_number = db.container_number;
      this.form.container_type_name = db.container_type_name;
      this.form.dock_container_type = db.dock_container_type;
      this.form.vehicle_ids = db.vehicle_ids;
      this.form.customer_user_id = parseInt(db.customer_user_id);
      this.form.cutt_off = db.cutt_off;
      this.form.destination = db.destination;
      this.form.eta = db.eta;
      this.form.export_date = db.export_date;
      this.form.export_images = db.export_images;
      this.form.export_invoice = db.export_invoice;
      this.form.consignee = db.houstan_custom_cover_letter.consignee;
      this.form.exporter_dob = db.houstan_custom_cover_letter.exporter_dob;
      this.form.exporter_id = db.houstan_custom_cover_letter.exporter_id;
      this.form.exporter_type_issuer =
        db.houstan_custom_cover_letter.exporter_type_issuer;
      this.form.notify_party = db.houstan_custom_cover_letter.notify_party;
      this.form.transportation_value =
        db.houstan_custom_cover_letter.transportation_value;
      this.form.ultimate_consignee_dob =
        db.houstan_custom_cover_letter.ultimate_consignee_dob;
      this.form.vehicle_location =
        db.houstan_custom_cover_letter.vehicle_location;
      this.form.id = db.id;
      this.form.loading_date = db.loading_date;
      this.form.note = db.note;
      this.form.port_of_discharge_name = db.port_of_discharge_name;
      this.form.port_of_loading_name = db.port_of_loading_name;
      this.form.seal_number = db.seal_number;
      this.form.special_instruction = db.special_instruction;
      this.form.streamship_line = db.streamship_line;
      this.form.terminal = db.terminal;
      this.form.vessel = db.vessel;
      this.form.xtn_number = db.xtn_number;

      this.form.export_invoice = db.export_invoice;

      this.form.export_invoice_photo = db.export_invoice_photo;
      this.form.container_images = db.container_images;
      this.form.empty_container_images = db.empty_container_images;
      this.form.loading_photos = db.loading_photos;
      this.form.loaded_photos = db.loaded_photos;

      // this.form.removableFilesExportInvoice =db.removableFilesExportInvoice
      // this.form.removableFilesContinerImage=db.removableFilesContinerImage
      // this.form.removableFilesEmptyContainer=db.removableFilesEmptyContainer
    },
    async edit() {
      if (!this.dataId) {
        return;
      }
      this.loading = true;
      this.errors = {};
      await Api.get(`/exports/${this.dataId}`)
        .then((res) => {
          // this.form = res.data.data.export_details;
          this.editData(res.data.data.export_details);
          this.vehicles = res.data.data.vehicles;
          // this.customarCompanyName = res.data.data.export_details.company_name;
          // console.log(this.form.loading_photos);
          // console.log(this.form.loaded_photos);
          // console.log(this.vehicles);

          let filePhoto = {
            size: this.form.export_invoice_photo.size,
            name: this.form.export_invoice_photo.name,
            type: this.form.export_invoice_photo.type,
          };
          this.$refs.vueDropzoneExprotInvoice.manuallyAddFile(
            filePhoto,
            this.form.export_invoice_photo.url
          );

          // this.$refs.vueDropzoneContinerImage.manuallyAddFile(filePhoto, 'http://localhost:8099/uploads/1618515249.png');
          // this.$refs.vueDropzoneEmptyContainer.manuallyAddFile(filePhoto, 'http://localhost:8099/uploads/1618515249.png');
          // this.$refs.refLoadingPhotos.manuallyAddFile(filePhoto, 'http://localhost:8099/uploads/1618515249.png');
          // this.$refs.refLoadedPhotos.manuallyAddFile(filePhoto, 'http://localhost:8099/uploads/1618515249.png');

          if (this.form.container_images.length > 0) {
            this.form.container_images.map((value) => {
              let file = {
                size: value.size,
                name: value.name,
                type: value.type,
              };
              this.$refs.vueDropzoneContinerImage.manuallyAddFile(
                file,
                value.url
              );
            });
          }

          // if(this.form.empty_container_photos.length > 0) {
          //     this.form.empty_container_photos.map((value, index, array) => {
          //         let file = { size: value.size, name: value.name, type: value.type };
          //         this.$refs.vueDropzoneEmptyContainer.manuallyAddFile(file, value.url);
          //     });
          // }

          if (this.form.loading_photos.length > 0) {
            this.form.loading_photos.map((value) => {
              let file = {
                size: value.size,
                name: value.name,
                type: value.type,
              };
              this.$refs.refLoadingPhotos.manuallyAddFile(file, value.url);
            });
          }

          if (this.form.loaded_photos.length > 0) {
            this.form.loaded_photos.map((value) => {
              let file = {
                size: value.size,
                name: value.name,
                type: value.type,
              };
              this.$refs.refLoadedPhotos.manuallyAddFile(file, value.url);
            });
          }

          this.dialog = true;
          this.loading = false;
        })
        .catch((err) => {
          // if (err.response.status === 422) {
          //     this.errors = err.response.data.errors;
          // } else {
          //     this.dialog = false;
          //     this.loading = false;
          // }

          this.$toastr.e("Failed to load data! " + err);
        });
    },
    cancel() {
      this.dataId = false;
      this.editing = false;
      this.dialog = false;
      this.$emit("cancelForm");
    },

    removeFileExportInvoice(file) {
      this.form.removableFilesExportInvoice.push(file.name);
    },
    removeFileContinerImage(file) {
      this.form.removableFilesContinerImage.push(file.name);
    },
    removeFileEmptyContainer(file) {
      this.form.removableFilesEmptyContainer.push(file.name);
    },

    removeFileLoadingPhotos(file) {
      this.form.removableFilesLoadingPhotos.push(file.name);
    },
    removeFileLoadedPhotos(file) {
      this.form.removableFilesLoadedPhotos.push(file.name);
    },

    sendingEventEmptyContainer(file, xhr, formData) {
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
    },

    sendingEventContinerImage(file, xhr, formData) {
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
    },
    sendingEventExprotInvoice(file, xhr, formData) {
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
    },

    sendingEventLoadingPhotos(file, xhr, formData) {
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
    },
    sendingEventLoadedPhotos(file, xhr, formData) {
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
    },

    storeSuccessExprotInvoice(file, response) {
      this.loading = false;
      this.form.export_invoice = response.data;
    },
    storeSuccessContinerImage(file, response) {
      this.loading = false;
      this.form.container_images.push(response.data);
    },
    storeSuccessEmptyContainer(file, response) {
      this.loading = false;
      this.form.empty_container_images.push(response.data);
    },

    storeSuccessLoadingPhotos(file, response) {
      this.loading = false;
      this.form.loading_photos.push(response.data);
    },

    storeSuccessLoadedPhotos(file, response) {
      this.loading = false;
      this.form.loaded_photos.push(response.data);
    },

    storeErrorExprotInvoice(file, message, xhr) {
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }

      this.$toastr.e("Failed to create data! " + message.message);
    },
    storeErrorContinerImage(file, message, xhr) {
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }

      this.$toastr.e("Failed to create data! " + message.message);
    },
    storeErrorEmptyContainer(file, message, xhr) {
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }

      this.$toastr.e("Failed to create data! " + message.message);
    },

    storeErrorLoadingPhotos(file, message, xhr) {
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }

      this.$toastr.e("Failed to create data! " + message.message);
    },

    storeErrorLoadedPhotos(file, message, xhr) {
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }

      this.$toastr.e("Failed to create data! " + message.message);
    },

    removeAllFilesExprotInvoice(dropzoneOnly = false) {
      this.removeFilesFromDropzoneOnlyExprotInvoice = dropzoneOnly;
      this.form.export_invoice = "";
      setTimeout(() => {
        this.$refs.vueDropzoneExprotInvoice.removeAllFiles();
      }, 200);
    },
    removeAllFilesContinerImage(dropzoneOnly = false) {
      this.removeFilesFromDropzoneOnlyContinerImage = dropzoneOnly;
      this.form.container_images = [];
      setTimeout(() => {
        this.$refs.vueDropzoneContinerImage.removeAllFiles();
      }, 200);
    },
    removeAllFilesEmptyContainer(dropzoneOnly = false) {
      this.removeFilesFromDropzoneOnlyEmptyContainer = dropzoneOnly;
      this.form.empty_container_images = [];
      setTimeout(() => {
        this.$refs.vueDropzoneEmptyContainer.removeAllFiles();
      }, 200);
    },

    removeAllFilesLoadingPhotos(dropzoneOnly = false) {
      this.removeFilesFromDropzoneOnlyLoadingPhotos = dropzoneOnly;
      this.form.loading_photos = [];
      setTimeout(() => {
        this.$refs.refLoadingPhotos.removeAllFiles();
      }, 200);
    },
    removeAllFilesLoadedPhotos(dropzoneOnly = false) {
      this.removeFilesFromDropzoneOnlyLoadingPhotos = dropzoneOnly;
      this.form.loaded_photos = [];
      setTimeout(() => {
        this.$refs.refLoadedPhotos.removeAllFiles();
      }, 200);
    },

    cusotmarAutoCompleteHandelar(e) {
      this.customarIdLoading = true;
      Api.get(`/customers-item?customer_name=${e}`)
        .then((res) => {
          this.items = res.data.data;
          this.customarIdLoading = false;
        })
        .catch((err) => {
          // console.log(err.response.data);
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          this.customarIdLoading = false;
          this.$toastr.e("Failed to create data! " + err);
        });
    },
    vinSearchAutoCompleteHandelar(vin) {
      this.containersLoading = true;
      Api.get(`/vehicle-search?vin=${vin}`)
        .then((res) => {
          this.vinSearch = res.data.data;
          this.containersLoading = false;
        })
        .catch((err) => {
          // console.log(err.response.data);
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          this.containersLoading = false;
          this.$toastr.e("Failed to create data! " + err);
        });
    },
    vinSearchTableData() {
      if (this.form.vehicle_ids.indexOf(this.vinSearchId) !== -1) {
        this.$toastr.e("Vehicle already exists! ");
        return;
      }

      Api.get(`vehicles/${this.vinSearchId}`)
        .then((res) => {
          if (
            this.vehicles.length &&
            this.vehicles[0].location_id !== res.data.data.location_id
          ) {
            this.$toastr.e(
              "Multiple location vehicle not allowed in one container!"
            );
            return;
          }
          this.vehicles.push(res.data.data);
          this.form.vehicle_ids.push(res.data.data.id);
          this.vinSearchId = "";
        })
        .catch((err) => {
          // if (err.response.status === 422) {
          //     this.errors = err.response.data.errors;
          // } else {
          //     this.dialog = false;
          //     this.loading = false;
          // }
          this.$toastr.e("Failed to create data! " + err);
        });
    },
    consigneeDataHandle() {
      Api.get(`consignee-search?customer_user_id=${this.form.customer_user_id}`)
        .then((res) => {
          this.consigneeData = res.data;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          this.$toastr.e("Failed to create data! " + err);
        });
    },
    customarInfoHandel() {
      for (const i of this.items) {
        if (i.user_id === this.form.customer_user_id) {
          this.customarCompanyName = i.company_name;
          this.legacyCustomerId = i.legacy_customer_id;
        }
      }
      // let info = this.items.find(e=>e.user_id===this.form.customer_user_id);
      // if (!info) {
      //     return
      // }
      // this.form.customer_user_id = info.user_id;

      // console.log('work');
      this.consigneeDataHandle();
    },
    cartItemsAdd() {
      let items = localStorage.getItem("asl_checkout");
      if (items) {
        items = JSON.parse(items);
      }

      const firstLocation = items ? items[Object.keys(items)[0]] : [];
      if (firstLocation) {
        firstLocation.forEach((e) => {
          this.vehicles.push(e);
          this.cartIdPass();
        });
      }
    },
    checkOutDelete(vid) {
      if (this.vehicles) {
        this.vehicles.splice(
          this.vehicles.findIndex((e) => e.id == vid),
          1
        );
      }

      this.cartIdPass();

      let items = localStorage.getItem("asl_checkout");
      if (items) {
        items = JSON.parse(items);
      }

      const firstLocation = items ? items[Object.keys(items)[0]] : [];

      if (firstLocation) {
        firstLocation.splice(
          firstLocation.findIndex((e) => e.id == vid),
          1
        );
      }

      if (!Array.isArray(firstLocation) || !firstLocation.length) {
        delete items[Object.keys(items)[0]];
        localStorage.setItem("asl_checkout", JSON.stringify(items));
        this.$store.commit("auth/removeCart", JSON.stringify(items));
        this.cartItemsAdd();
        return;
      }
      localStorage.setItem("asl_checkout", JSON.stringify(items));
    },

    cartIdPass() {
      this.form.vehicle_ids = [];
      this.vehicles.forEach((e) => {
        this.form.vehicle_ids.push(e.id);
      });
    },

    fetchEntriesDebounced(val) {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.cusotmarAutoCompleteHandelar(val);
      }, 700);
    },
  },
  created() {
    this.emptyForm();
    this.cartItemsAdd();
  },
  watch: {
    id: function (newVal, oldVal) {
      if (newVal || oldVal) {
        this.dataId = newVal;
        this.edit();
        this.editing = true;
      } else {
        this.dataId = false;
        this.editing = false;
      }
    },
    show: function (newVal) {
      this.dialog = newVal;
    },
    "form.customer_user_id": function () {
      this.customarInfoHandel();
    },
    searchVinId: function (newVal) {
      if (newVal) {
        this.vinSearchAutoCompleteHandelar(newVal);
      }
    },
    customarUserId: function (newVal) {
      // if (newVal) {
      //   this.cusotmarAutoCompleteHandelar(newVal);
      // }

      if (!newVal) {
        return;
      }

      this.fetchEntriesDebounced(newVal);
    },
  },
};
</script>
<style scoped>
.export-modal .row .col-12 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.export-modal__title {
  padding-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
}
.export-modal hr {
  background: #ebebeb;
}
</style>
